import clsx from "clsx";
import React from "react";
import styles from "./index.module.css";

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
  className?: string;
};

export const Chip = ({ children, onClick, active, className }: Props) => {
  return (
    <button
      className={clsx(className, styles.root, active && styles.active)}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
