"use client";

import { Chip } from "@/components/ui/Chip";

import { CrossIcon } from "@/components/icons/CrossIcon";
import { useCategoryParams } from "@/features/product-listing/hooks";
import { FilterValue } from "@/lib/centra/hooks/useProductFilter/useProductFilter";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import styles from "./index.module.css";

type Props = {
  categories: FilterValue[];
};

const CategoryTags = ({ categories }: Props) => {
  const categoryParams = useCategoryParams();

  const [...rest] = categories;

  const sortedByActive = rest.toSorted((a, b) => {
    const aActive = categoryParams.has(a.data.category);
    const bActive = categoryParams.has(b.data.category);

    if (aActive && !bActive) return -1;
    if (!aActive && bActive) return 1;
    return 0;
  });

  return (
    <div className={styles.categoryTags}>
      <All />
      {sortedByActive.map((category, index) => (
        <CategoryTag
          key={category.data.category}
          category={category}
          index={index + 1}
        />
      ))}
    </div>
  );
};

export default CategoryTags;

const All = () => {
  const categoryParams = useCategoryParams();

  const t = useTranslations();

  return (
    <Chip onClick={() => categoryParams.clear()}>
      <span>{t("common.all")}</span>
    </Chip>
  );
};

const CategoryTag = ({
  category,
  index,
}: {
  category: FilterValue;
  index: number;
}) => {
  const t = useTranslations();
  const categoryId = category.data.category;
  const categoryParams = useCategoryParams();

  const active = categoryParams.has(categoryId);

  const toggleCategory = () => {
    active
      ? categoryParams.delete(categoryId)
      : categoryParams.append(categoryId);
  };

  // ["women", "tops"] -> ["tops"]
  const name = index === 0 ? t("common.all") : category.data.name.at(-1);

  return (
    <Chip className={clsx(styles.tag)} active={active} onClick={toggleCategory}>
      <span>{name}</span> {active && <CrossIcon />}
    </Chip>
  );
};
