"use client";

import { loadStoryblokBridge } from "@storyblok/js";
import { Suspense, useEffect, useState } from "react";

import { useRouter } from "next/navigation";
import StoryblokComponent from "../StoryblokComponent/StoryblokComponent";
import type { StoryblokStoryProps } from "./StoryblokStory";

const bridgeLoader = (
  setState: (newStory: any) => void,
  router: ReturnType<typeof useRouter>
) => {
  loadStoryblokBridge()
    .then(() => {
      const { StoryblokBridge } = window;
      const storyblokInstance = new StoryblokBridge();

      storyblokInstance.on(["published", "change"], () => {
        router.refresh();
      });

      storyblokInstance.on(["input"], (e) => {
        setState(e?.story?.content);
      });
    })
    .catch((err) => console.error(err));
};

const ClientStoryblokStory = ({ story, ...restProps }: StoryblokStoryProps) => {
  const [blokState, setBlokState] = useState(story.content);
  const router = useRouter();

  useEffect(() => {
    bridgeLoader(
      (newStory: typeof blokState) => setBlokState(newStory),
      router
    );
  }, [router]);

  return (
    <Suspense>
      <StoryblokComponent blok={blokState} {...restProps} preview />
    </Suspense>
  );
};

export default ClientStoryblokStory;
